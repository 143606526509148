// JS / Misc / Navbar

import 'arrive'
import $ from 'jquery'

// Funzione per controllare se un elemento è figlio di un altro elemento
const isDescendant = function (parent, child) {
  let node = child.parentNode;
  while (node) {
      if (node === parent) {
          return true;
      }

      // Traverse up to the parent
      node = node.parentNode;
  }

  // Go up until the root but couldn't find the `parent`
  return false;
};

var scrollLimit = 100;

// MegaMenu Desktop OPEN-CLOSE + bottom border
document.arrive('.nav-link', {existing: true}, function() {
  const menuItem = this.parentElement;

  const megaMenu = document.querySelector(".oc-header-nav");

  if(isDescendant(megaMenu, menuItem)){

    menuItem.onmouseover = () => {
      if (menuItem.classList.contains("nav-item")) {  //controllare se è megamenu
        if(menuItem.querySelector(".dropdown-menu")){
          var dropdown = menuItem.querySelector(".dropdown-menu");
          dropdown.classList.add("active");
        }
        menuItem.style ="border-bottom: 3px solid #31CCD0;";
      }
    }

    menuItem.onmouseout = () => {
      if (menuItem.classList.contains("nav-item")) {
        if(menuItem.querySelector(".dropdown-menu")){
          var dropdown = menuItem.querySelector(".dropdown-menu");
          dropdown.classList.remove("active");
        }
        menuItem.style ="";
      }
    }
  }
});

// MegaMenu Desktop BACKGROND bar when on top
document.arrive('.oc-header-nav', {existing: true}, function() {
  const megaMenu = this;


  if(megaMenu.dataset.isfront == "true"){
    megaMenu.onmouseover = () => {
      if (window.scrollY >= scrollLimit) {
      } else {
        //Sopra il limite
        megaMenu.classList.remove("trasparente");
      }
    }

    megaMenu.onmouseout = () => {
      if (window.scrollY >= scrollLimit) {
      } else {
        //Sopra il limite
        megaMenu.classList.add("trasparente");
      }
    }

  }
});


window.onscroll = function() {
  const megaMenu = document.querySelector(".oc-header-nav");

  if(megaMenu.dataset.isfront == "true"){
    if (window.scrollY >= scrollLimit) {
      //Sotto il limite
      document.querySelector(".oc-header-nav").classList.remove("trasparente");
    } else {
      //Sopra il limite
      document.querySelector(".oc-header-nav").classList.add("trasparente");
    }
  }

};


// Open Menu Mobile
document.arrive('#mobile-menu-hamburgher', {existing: true}, function() {

  const menuHam = this;
  const mobileHead = menuHam.parentElement.parentElement;

  //  .full-mobile-menu
  const mobileMenu = mobileHead.nextElementSibling;


  menuHam.onclick = () => {

    //Hide Mobile Menu Bar
    mobileHead.classList.add("hide-bar");

    //Show full screen box with bar and menu inside
    mobileMenu.classList.add("show-menu-mobile");


  }



});


// Close Menu Mobile
document.arrive('#mobile-menu-closebtn', {existing: true}, function() {

  const menuHam = this;
  const mobileMenu = menuHam.parentElement.parentElement.parentElement;

  //  .full-mobile-menu
  const mobileHead = (mobileMenu.parentElement).children[2];


  menuHam.onclick = () => {

    //Hide Mobile Menu Bar
    mobileHead.classList.remove("hide-bar");

    //Show full screen box with bar and menu inside
    mobileMenu.classList.remove("show-menu-mobile");


  }



});

// Apertura 1° Livello Mobile
document.arrive('.nav-link', {existing: true}, function() {
  const navLink = this;
  const mobileMenu = document.querySelector(".mobile-mobile");

  if(isDescendant(mobileMenu, navLink)){
    // Parte del menu mobile

    if(navLink.parentElement.classList.contains("menu-item-has-children")){
      // Ha un sotto menu quindi deve essere cliccabile

      navLink.onclick = () => {

        event.preventDefault();
        // Zona per menu di primo livello
        const lvl1 = document.querySelector(".clone-menu-1");

        // Copia menu nella prima sezione
        lvl1.children[1].innerHTML = navLink.textContent;
        lvl1.appendChild(navLink.parentElement.children[1].cloneNode(true));

        const mobiles = document.querySelector(".mobile-mobile").parentElement.parentElement;
        mobiles.children[0].classList.toggle("nascondi");
        mobiles.children[1].classList.toggle("nascondi");
        mobiles.children[2].classList.toggle("nascondi");

        event.preventDefault();
      }
    }
  }

});


// Chiusura 1° Livello Mobile
document.arrive('.title1a', {existing: true}, function() {

  const tuttoLink = this;

  tuttoLink.onclick = () => {

    event.preventDefault();

    // Rimuove Menu 1° livello
    tuttoLink.parentElement.removeChild(tuttoLink.parentElement.children[2]);

    const mobiles = document.querySelector(".mobile-mobile").parentElement.parentElement;
    mobiles.children[0].classList.toggle("nascondi");
    mobiles.children[1].classList.toggle("nascondi");
    mobiles.children[2].classList.toggle("nascondi");
  }

});

// Apertura 2° Livello Mobile
document.arrive('.menu-item-has-children:not(:last-child)', {existing: true}, function() {
  const navLink = this;
  const mobileMenu1LVL = document.querySelector(".clone-menu-1");

  if(isDescendant(mobileMenu1LVL, navLink)){
    // Parte del menu mobile
    navLink.onclick = () => {
      event.preventDefault();

      // Zona per menu di secondo livello
      const lvl2 = document.querySelector(".clone-menu-2");

      // Copia menu nella seconda sezione
      lvl2.children[1].innerHTML = navLink.children[0].children[1].textContent;
      lvl2.appendChild(navLink.children[1].cloneNode(true));

      document.querySelector(".title1b").innerHTML = document.querySelector(".title2a").textContent;

      const mobiles = document.querySelector(".mobile-mobile").parentElement.parentElement;
      mobiles.children[2].classList.toggle("nascondi");
      mobiles.children[3].classList.toggle("nascondi");

      $('.oc-header').addClass('opened-2lv');

    }
  }

});

// Chiusura 2° Livello Mobile
document.arrive('.title1b', {existing: true}, function() {
  const tuttoLink = this;

  tuttoLink.onclick = () => {

    event.preventDefault();

    // Rimuove Menu 2° livello
    tuttoLink.parentElement.removeChild(tuttoLink.parentElement.children[2]);

    const mobiles = document.querySelector(".mobile-mobile").parentElement.parentElement;
    mobiles.children[2].classList.toggle("nascondi");
    mobiles.children[3].classList.toggle("nascondi");

    $('.oc-header').removeClass('opened-2lv')

  }

});

// Aggiunta classi menu di ricerca
document.arrive('.oc-search-box', {existing: true}, function() {

  var $searchBox = $(this);
  var btnClose = $('.oc-btn-close');

  btnClose.onclick = () => {
    $searchBox.find('form').removeClass('focus');
  }
  
  $( window ).on('resize',function() {
    if($( window ).width() > 1279){
      $searchBox.on('mouseenter', function(){
        $searchBox.find('form').addClass('focus');
      }).on('mouseleave', function(){ 
        $searchBox.find('form').removeClass('focus');
      });
    } else {
      $searchBox.find('input[name="search"]').on('focus', function() {
        $searchBox.find('form').addClass('focus');
      }).on('blur', function() {
      });
    }
  });

  if($( window ).width() > 1279){
    $searchBox.on('mouseenter', function(){
      $searchBox.find('form').addClass('focus');
    }).on('mouseleave', function(){ 
      $searchBox.find('form').removeClass('focus');
    });
  } else {

    $(window).on('click', function() {
      $searchBox.find('form').removeClass('focus');
    });
    
    $searchBox.on('click', function(event){
      event.stopPropagation();
      $searchBox.find('form').addClass('focus');
    });

  }

});

document.arrive('.oc-header .is-menu-bottom', {existing: true}, function() {

  var $block = $(this);

});