// JS / Widgets / Accordion

import 'arrive'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

document.arrive('.oc-accordion', {existing: true}, function() {

  gsap.registerPlugin(ScrollTrigger)

  const accordion = this;

  const button = accordion.querySelector('.oc-acc-title');
  const panel = accordion.querySelector('.oc-acc-content');

  if ( accordion.classList.contains('oc-active') ) {

    const panel = accordion.querySelector('.oc-acc-content');

    gsap.set(panel, {

      delay: 0,
      duration: 0.3,
      height: 'auto',
      ease: 'power2.out',

    });

  }

  button.onclick = () => {

    if ( !accordion.classList.contains('oc-active') ) {

      const accordions = document.querySelectorAll('.oc-accordion');

      accordions.forEach( accordion_ => {

        if ( accordion_.classList.contains('oc-active') ) {

          const panel = accordion_.querySelector('.oc-acc-content');

          gsap.to(panel, {

            delay: 0,
            duration: 0.3,
            height: 0,
            ease: 'power2.out',

          });

          accordion_.classList.remove('oc-active');

        }

      });

      gsap.to(panel, {

        delay: 0.3,
        duration: 0.3,
        height: 'auto',
        ease: 'power2.out',

      });

      accordion.classList.add('oc-active');

    } else {

      gsap.to(panel, {

        delay: 0,
        duration: 0.3,
        height: 0,
        ease: 'power2.out',

      });

      accordion.classList.remove('oc-active');

    }

  }

});
