// JS / Main

import AOS from 'aos'

AOS.init({
  offset: 200,
  duration: 600,
  once: true
});

document.querySelectorAll('img').forEach( (img) =>
  img.addEventListener('load', () => AOS.refresh()
));

// IMPORT

import lazy from './helpers/_lazy-load'
import slider from './swiper/_slider'
import accordion from './widgets/_accordion'
import counter from './widgets/_counter'
import toggle from './misc/_toggle'
import navbar from './misc/_navbar'
import footer from './misc/_footer'
import downloads from './listing/_downloads'
import filters from './modals/_filters'
import newsletter from './modals/_newsletter'
import locations from './widgets/_store-locator'
import whishlist from './misc/_whishlist'
import download from './misc/_download'
