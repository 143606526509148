// JS / Swiper / Slider

import 'arrive'
import Swiper, { Navigation, Pagination, Scrollbar, Autoplay } from 'swiper'
Swiper.use([Navigation, Pagination, Scrollbar, Autoplay])

document.arrive('.oc-slider', {existing: true}, function() {

  const slider = this;

  let mobile = parseFloat(slider.getAttribute('data-slider-mobile'));
  let touch = slider.getAttribute('data-slider-touch');

  const desktop = parseFloat(slider.getAttribute('data-slider-desktop'));
  const center = parseInt(slider.getAttribute('data-slider-center'));
  const gap = parseInt(slider.getAttribute('data-slider-gap'));
  const initial = parseInt(slider.getAttribute('data-slider-initial'));
  const autoplay = parseInt(slider.getAttribute('data-slider-autoplay'));
  const loop = parseInt(slider.getAttribute('data-slider-loop'));
  const pagination = slider.getAttribute('data-slider-pagination');

  let viewXl = desktop;
  let viewLg = desktop;
  let viewMd = mobile;

  let play = false;
  let gapMobile = 0;

  if ( !mobile ) {
    viewMd = desktop;
  }

  if ( !touch ) {
    touch = false;
  }

  if ( viewXl > 3 ) {
    viewLg = viewXl - 1;
    viewMd = viewXl - 1;
  }

  if ( viewXl > 4 ) {
    viewLg = viewXl - 1;
    viewMd = viewXl - 2;
  }

  if ( autoplay ) {

    play = {
      delay: 5000,
      disableOnInteraction: true,
    };

  }

  const getTimeout = function () {

    const e = setTimeout, b = {};
    setTimeout = function (a, c) {
      const d = e(a, c); b[d] = [Date.now(), c]; return d
    };

    return function (a) {
      return (a = b[a]) ? Math.max(a[1] - Date.now() + a[0], 0) : NaN
    }

  }();

  function sanitisePercentage(i) {
    return Math.min(100, Math.max(0, i));
  }

  let percentTime = 0;
  let tick = null;
  const progressBar = slider.querySelector('.oc-slider-pagination');

  const slider_ = new Swiper(slider, {

    loop: loop,
    speed: 600,
    direction: 'horizontal',
    effect: 'slide',
    centeredSlides: center,
    initialSlide: initial,
    keyboard: false,
    allowTouchMove: touch,
    slidesPerView: desktop,
    spaceBetween: gap,

    pagination: {
      el: slider.querySelector('.oc-slider-pagination'),
      clickable: true,
      type: pagination,
    },

    navigation: {
      nextEl: slider.querySelector('.oc-nav-next'),
      prevEl: slider.querySelector('.oc-nav-prev'),
    },

    autoplay: play,
    watchOverflow: true,

    watchSlidesProgress: true,
    watchSlidesVisibility: true,
    roundLengths: true,

    on: {
      slideChange: function() {

        if ( pagination == 'progressbar' ) {

          const swiper = this;
          const delay = 5000;
          const currIndex = swiper.realIndex + 1;
          const currSlide = swiper.slides[currIndex];

          updateProgressBar(progressBar, delay);

        }

      }
    },

    breakpoints: {

      0: {
        allowTouchMove: true,
        slidesPerView: mobile,
        autoplay: false,
      },

      960: {
        slidesPerView: viewMd
      },

      1280: {
        slidesPerView: viewLg
      },

      1440: {
        slidesPerView: viewXl
      }

    },

  });

  function updateProgressBar(bar, delay) {

    function startProgressBar() {
      resetProgressBar();
      tick = setInterval(progress, 50);
    }

    function progress() {

      const timeLeft = getTimeout(slider_.autoplay.timeout);

      if (slider_.autoplay.running && !slider_.autoplay.paused) {
        percentTime = sanitisePercentage(100 - Math.round(timeLeft / delay * 100));
        bar.style.width = percentTime + '%';

        if (percentTime > 100) {
          resetProgressBar();
        }
      }

      if (slider_.autoplay.paused) {
        percentTime = 0;
        bar.style.width = 0;
      }

    }

    function resetProgressBar() {
      percentTime = 0;
      bar.style.width = 0;
      clearInterval(tick);
    }

    startProgressBar();

  }

});
