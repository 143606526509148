import 'arrive';
import $ from 'jquery';
import ModalVideo from 'modal-video';

//
//  Event trigger video modal
//
document.arrive('.oc-btn-video', { existing: true }, function(){

  var $elementVideo = $(this);
  var id = $elementVideo.data('id');

  new ModalVideo('.modal-video-'+id );

});