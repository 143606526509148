// JS / Misc / Toggle

import 'arrive'

document.arrive('.oc-btn-more', {existing: true}, function() {

  const button = this;
  const section = button.closest('.oc-section');

  button.onclick = () => {

    if ( section.classList.contains('oc-section-more') ) {
      section.classList.remove('oc-section-more');

    } else {
      section.classList.add('oc-section-more');

    }

  }

});
