// JS / Helpers / LazyLoad

import LazyLoad from 'vanilla-lazyload'

function ocLazyLoad() {

  const initImage = new LazyLoad({
    elements_selector: '.oc-lazy',
    thresholds: '30%',
  });

  // const initSlide = new LazyLoad({
  //   elements_selector: '.swiper-lazy',
  //   thresholds: '30%',
  // });

}

ocLazyLoad();
