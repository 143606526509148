// JS / Modal / Filters

import 'arrive'

// Apertura modal newsletter click bottone modulo footer
document.arrive('#newsletter-modal-opener', {existing: true}, function() {

  const btn = this;
  const modal = document.getElementById("newsletter-modal");

  btn.onclick = function() {
    modal.style.display = "block";
  }

});

// Chiusura modal newsletter click "x"
document.arrive('.mod-newsletter-close', {existing: true}, function() {

  const btn = this;
  const modal = document.getElementById("newsletter-modal");

  btn.onclick = function() {
    modal.style.display = "none";
  }

});


document.arrive("#newsletter-modal", {existing: true}, function() {
  
  const modal = this;

  window.onclick = function(event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  }

});