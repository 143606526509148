// JS / Widgets / Counter

import 'arrive'
import CountUp from 'countup.js'
import ScrollMagic from 'scrollmagic'

function ocCount(node, start, speed) {

  const print = node.querySelector('.oc-value');
  const end = parseInt(node.getAttribute('data-counter-end'));
  let stop = parseInt(node.getAttribute('data-counter-stop'));

  if ( stop == 0 ) {

    const count = new CountUp(print, end, {
      startVal: 0,
      decimalPlaces: 0,
      duration: speed
    });

    stop++;
    node.setAttribute('data-counter-stop', stop);

    count.start();

  }

}

document.arrive('.oc-counter', {existing: true}, function() {

  const counter = this;
  const controller = new ScrollMagic.Controller();

  new ScrollMagic.Scene({

    triggerElement: counter,
    triggerHook: .75

  }).on('enter', (e) => {

    ocCount(counter, 0, 1.5);

  }).addTo(controller);

});
