// JS / Misc / Navbar

import 'arrive';

document.arrive('.footer-title-menu', {existing: true}, function() {

  const title = this;
  const extDiv = this.parentElement;
  const accordion = extDiv.children[1].children[0];

  /*this.click(function() {
    alert( "Handler for .click() called." );
  });*/

  this.addEventListener("click", function() {
    title.classList.toggle("opened");
    accordion.classList.toggle("active");
    
    //var content = this.nextElementSibling;
    if (accordion.style.maxHeight){
      accordion.style.maxHeight = null;
    } else {
      accordion.style.maxHeight = accordion.scrollHeight + "px";
    }
  });

});