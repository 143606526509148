// JS / Modal / Filters

import 'arrive'
import gsap from 'gsap'

document.arrive('.oc-modal-filters', {existing: true}, function() {

  const modal = this;
  const btnOpen = document.querySelector('.oc-btn-filter');
  const btnClose = modal.querySelector('.oc-btn-close');
  const btnApply = modal.querySelector('.oc-btn-apply');

  const openModal = () => {
    if ( !modal.classList.contains('oc-active') ) {

      modal.classList.add('oc-active');

      gsap.to(modal, {

        x: 0,
        duration: 0.3,
        ease: 'power2.out',

      });

    }
  }

  const closeModal = () => {
    if ( modal.classList.contains('oc-active') ) {

      gsap.to(modal, {

        x: '-100%',
        duration: 0.3,
        ease: 'power2.out',

        onComplete: () => {
          modal.classList.remove('oc-active');
        }

      });

    }
  }

  btnOpen.onclick = () => {
    openModal();
  }

  btnApply.onclick = () => {
    closeModal();
  }

  btnClose.onclick = () => {
    closeModal();
  }

});
