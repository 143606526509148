import 'arrive'
import $ from 'jquery'

//
//  Modals
//

var base_url_site, map, marker1, marker;

//
// Check data inside array
function arrayContainsArray(superset, subset) {
    if (0 === subset.length) {
        return true;
    }
    return subset.every(function (value) {
        return (superset.indexOf(value) >= 0);
    });
}

//
//  Generate maps with Google API
//
document.arrive('.modules-store-locator',{existing:true},function(){

    //Define base URL
    base_url_site = $('.modules-store-locator').data('base');

    //List of locations
    var marker_list = [];

    //User Marker
    var markers_user = [];

    //Config bound for auto-zoom
    var bounds = new google.maps.LatLngBounds();

    //Define cluster for markers
    var mc;

    //Cluster options
    var optionsCluster = {
        maxZoom: 13,
        gridSize: 50,
        styles: [{
            textColor: '#ffffff',
            url: base_url_site+'/img/group_r.svg',
            width: 53,
            height: 53
        }]
    };

    //Map options
    var mapOptions = {
        maxZoom: 15, //set max-zoom expecially for bounds
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        styles: [ { "featureType": "all", "elementType": "labels.text.fill", "stylers": [ { "saturation": 36 }, { "color": "#000000" }, { "lightness": 40 } ] }, { "featureType": "all", "elementType": "labels.text.stroke", "stylers": [ { "visibility": "on" }, { "color": "#000000" }, { "lightness": 16 } ] }, { "featureType": "all", "elementType": "labels.icon", "stylers": [ { "visibility": "off" } ] }, { "featureType": "administrative", "elementType": "geometry.fill", "stylers": [ { "color": "#000000" }, { "lightness": 20 } ] }, { "featureType": "administrative", "elementType": "geometry.stroke", "stylers": [ { "color": "#000000" }, { "lightness": 17 }, { "weight": 1.2 } ] }, { "featureType": "landscape", "elementType": "geometry", "stylers": [ { "color": "#000000" }, { "lightness": 20 } ] }, { "featureType": "poi", "elementType": "geometry", "stylers": [ { "color": "#000000" }, { "lightness": 21 } ] }, { "featureType": "road.highway", "elementType": "geometry.fill", "stylers": [ { "color": "#000000" }, { "lightness": 17 } ] }, { "featureType": "road.highway", "elementType": "geometry.stroke", "stylers": [ { "color": "#000000" }, { "lightness": 29 }, { "weight": 0.2 } ] }, { "featureType": "road.arterial", "elementType": "geometry", "stylers": [ { "color": "#000000" }, { "lightness": 18 } ] }, { "featureType": "road.local", "elementType": "geometry", "stylers": [ { "color": "#000000" }, { "lightness": 16 } ] }, { "featureType": "transit", "elementType": "geometry", "stylers": [ { "color": "#000000" }, { "lightness": 19 } ] }, { "featureType": "water", "elementType": "geometry", "stylers": [ { "color": "#000000" }, { "lightness": 17 } ] } ]
    };

    //
    //  Function add Marker
    //
    function addMarker(marker) {

        //Marker variables
        var id = marker[0];
        var position = new google.maps.LatLng(marker[1], marker[2]);

        //Position
        bounds.extend(position);

        //Scelta dell'immagine

        var url_image_marker = base_url_site+'/assets/img/map-point.svg';

        var marker1 = new google.maps.Marker({
            id: id,
            position: position,
            map: map,
            icon: {
                url: url_image_marker,
                scaledSize: new google.maps.Size(22, 22)
            },
        });

        const infowindow = new google.maps.InfoWindow({
            content: '<div class="tooltip-map"><span class="title">'+marker[3]+'</span><span class="address">'+marker[4]+'</span><a href="https://www.google.com/maps/place/'+marker[1]+','+marker[2]+'" target="_blank">Ottieni indicazioni</a></div>'
        });
        marker1.addListener("click", () => {
            for(var i = 0; i < marker_list.length; i++){
                const matches = document.querySelectorAll("button.gm-ui-hover-effect").forEach(el=>el.click());
            }
            infowindow.open({
                anchor: marker1,
                map,
              });          
        });

        //Push marker to list
        marker_list.push(marker1);

    }//fin addMarker()


    //
    //  Init map and the components
    //
    function initialize() {

        //Init map
        map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

        //Add markers
        for (var i = 0; i < markers1.length; i++) {
            if(markers1[i][1] && markers1[i][2]){
                addMarker(markers1[i]);
            }
        }

        //Define initial bound
        map.fitBounds(bounds);
        map.panToBounds(bounds);

        //Init cluster
        mc = new MarkerClusterer(map, marker_list, optionsCluster);

        //
        // Init input
        $('.filter_container').on('submit',function(event){
            event.preventDefault();
        });

        var input = document.getElementById('location-input');
        var searchBox = new google.maps.places.SearchBox(input);

        map.addListener('bounds_changed', function() {
            searchBox.setBounds(map.getBounds());

            var bounds = map.getBounds();
            for(var i = 0; i < marker_list.length; i++){     
                if(bounds.contains(marker_list[i].position)){
                    // Set list places visible after research
                    $('.oc-list-store .oc-location-item[data-id="'+marker_list[i].id+'"]').find('.oc-decoration-line').removeClass('oc-bg-[#ccc]');
                    $('.oc-list-store .oc-location-item[data-id="'+marker_list[i].id+'"]').find('.oc-decoration-line').addClass('oc-bg-[#31CCD0]');
                    // $('.oc-list-store .oc-location-item[data-id="'+marker_list[i].id+'"]').addClass('oc-order-1');
                } else {
                    $('.oc-list-store .oc-location-item[data-id="'+marker_list[i].id+'"]').find('.oc-decoration-line').addClass('oc-bg-[#ccc]');
                    $('.oc-list-store .oc-location-item[data-id="'+marker_list[i].id+'"]').find('.oc-decoration-line').removeClass('oc-bg-[#31CCD0]');
                    // $('.oc-list-store .oc-location-item[data-id="'+marker_list[i].id+'"]').removeClass('oc-order-1');
                    // $('.oc-list-store .oc-location-item[data-id="'+marker_list[i].id+'"]').addClass('oc-order-2');
                }
            }

        });

        searchBox.addListener('places_changed', function(){

            var places = searchBox.getPlaces();

            if (places.length == 0) {
                return;
            }

            // Clear out the old markers.
            markers_user.forEach(function(marker) {
                marker.setMap(null);
            });

            markers_user = [];

            // For each place, get the icon, name and location.
            var bounds = new google.maps.LatLngBounds();

            places.forEach(function(place) {

                if (!place.geometry) {
                    console.log("Returned place contains no geometry");
                    return;
                }

                var icon = {
                    url: base_url_site + '/wp-content/themes/vega-theme/images/place.svg',
                    size: new google.maps.Size(70, 70),
                    origin: new google.maps.Point(0, 0),
                    anchor: new google.maps.Point(17, 34),
                    scaledSize: new google.maps.Size(40, 40)
                };

                // Create a marker for each place.
                markers_user.push(new google.maps.Marker({
                    map: map,
                    icon: icon,
                    title: place.name,
                    position: place.geometry.location
                }));

                if (place.geometry.viewport) {
                    // Only geocodes have viewport.
                    bounds.union(place.geometry.viewport);
                } else {
                    bounds.extend(place.geometry.location);
                }

            });

            map.fitBounds(bounds);

            searchBox.setBounds(map.getBounds());

            var bounds = map.getBounds();
            var emptyMarker = true;
            for(var i = 0; i < marker_list.length; i++){     
                if(bounds.contains(marker_list[i].position)){
                    emptyMarker = false;
                    break;
                }
            }

            if(emptyMarker){
                map.setZoom(7);
            }

        });

    }

    //
    // Init map
    initialize();

    //
    // Events fire on filter change
    $('.filter_container').on('change', function(){

        bounds = new google.maps.LatLngBounds();

        var selected_values = $('.filter_container').serializeArray();
        var array_values = [];

        for(var i=0; i < selected_values.length; i++){

            if(selected_values[i]["value"] == ""){
                continue;
            }

            var name = selected_values[i]["name"];
            if(name.indexOf(",") >= 0) {

                var arr = name.split(',');

                $.each(arr,function(index,value){
                    if(!isNaN(parseInt(value))) {
                        array_values.push(parseInt(value));
                    }
                });

            } else if (!isNaN(parseInt(name))) {
                // es: filtri prodotti e carte
                array_values.push(parseInt(name));
            } else {
                // es: filtro stazione
                array_values.push(selected_values[i]["value"]);
            }

        }

        var visible_marker = [];

        for(i=0; i < markers1.length; i++){

            marker = marker_list[i];

            if(arrayContainsArray(marker.taxonomy,array_values)){
                marker.setVisible(true);
                visible_marker.push(marker);
                bounds.extend(marker.getPosition());
            } else {
                marker.setVisible(false);
            }

        }

        mc.repaint();

        $([document.documentElement, document.body]).animate({
            scrollTop: $('html').height() - $("footer").offset().top - 200
        }, 500);

        if(visible_marker.length >= 1) {
            if ($('#location-input').val().length < 1 || array_values.length < 1) {

                if (map.getZoom() < 14 || array_values.length == 0) {
                    map.fitBounds(bounds);
                    map.panToBounds(bounds);
                }

            }
        }

    });

    $('.oc-location-item').on('click', function(e){

        e.preventDefault();

        $('.oc-location-item').each(function(){
            $(this).find('.oc-decoration-line').removeClass('oc-bg-[#31CCD0]');
            $(this).find('.oc-decoration-line').addClass('oc-bg-[#ccc]');
        });
        $(this).find('.oc-decoration-line').removeClass('oc-bg-[#ccc]');
        $(this).find('.oc-decoration-line').addClass('oc-bg-[#31CCD0]');

        var lat = parseFloat($(this).data('lat'));
        var lng = parseFloat($(this).data('lng'));
        var id = parseFloat($(this).data('id'));

        map.setCenter(new google.maps.LatLng(lat, lng));
        map.setZoom(14);

        marker_list.forEach(marker => {
            if(id == marker.id){
                google.maps.event.trigger(marker, 'click');
            } else {
                google.maps.event.trigger(marker, 'closeclick');
            }
        })

    });

});