import 'arrive';
import $ from 'jquery';
import Cookies from 'js-cookie'

//
//  Btn event add to whishlist
//
document.arrive('.oc-btn-to-wish', { existing: true }, function(){

  var $btn = $(this);

  $btn.on('click', function(){

    var array_products = [];
    var $btnTmp = $(this);
    var $labelBtn = $btnTmp.find('> a > .oc-label');
    var productID = $btnTmp.find('> a').data('product_id');
    var cookie_products = Cookies.get('whishlist-ids');

    //
    // If cookie exist, decode the string
    if(cookie_products){
      if(cookie_products.indexOf(",") >= 0){
        array_products = cookie_products.split(",");
      } else {
        array_products.push(cookie_products);
      }
    }

    if($.inArray(productID+"", array_products) < 0){
      array_products.push(productID);
      $labelBtn.text('rimuovi dalla whishlist')
    } else {
      array_products.pop(productID);
      $labelBtn.text('aggiungi alla whishlist')
    }

    //
    //  30 days as expiration date of the cookie
    //
    Cookies.set('whishlist-ids', array_products, { expires: 30 });

    check_counter_wishlist();

  })

});

//
// Remove product from whishlist in wishlist page
document.arrive('.oc-wishlist .oc-item-wishlist .oc-btn-remove', { existing: true }, function(){

  // variables
  var array_products = [];
  var productID = $(this).data('product_id');
  var cookie_products = Cookies.get('whishlist-ids');

  //
  // If cookie exist, decode the string
  if(cookie_products){
    if(cookie_products.indexOf(",") >= 0){
      array_products = cookie_products.split(",");
    } else {
      array_products.push(cookie_products);
    }
  }

  $(this).on('click', function(){
    array_products.pop(productID);
    Cookies.set('whishlist-ids', array_products, { expires: 30 });
    check_counter_wishlist();
    location.reload(); 
  });

});

//
//  Cookie check
//

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function check_counter_wishlist(){

  var $btn = $('.oc-svg-wishlist');

  if(document.cookie.indexOf('whishlist-ids') > 0 && getCookie('whishlist-ids') != ''){
    
    var values_cookie = decodeURIComponent(getCookie('whishlist-ids')).split(',');

    $btn.find('.counter').text(values_cookie.length);
    $btn.find('.counter').addClass('active');

  } else {
    $btn.find('.counter').text('');
    $btn.find('.counter').removeClass('active');
  }

}

document.arrive('.oc-svg-wishlist', { existing: true }, function(){

  check_counter_wishlist();

});