// JS / Listing / Downloads

import 'arrive'

document.arrive('.oc-listing-downloads', {existing: true}, function() {

  const listing = this;

  const filters = listing.querySelector('.oc-filters');
  const options = filters.querySelectorAll('.oc-option');

  const grid = listing.querySelector('.oc-grid');
  const columns = grid.querySelectorAll('.oc-column');

  options.forEach( option => {

    const input = option.querySelector('input');

    input.onclick = () => {

      const value = input.value;

      if ( option.classList.contains('oc-active') ) {

        input.checked = false;
        option.classList.remove('oc-active');

        columns.forEach( column => {

          if ( column.classList.contains('oc-hidden') ) {
            column.classList.remove('oc-hidden');
          }

        });

      } else {

        input.checked = true;
        option.classList.add('oc-active');

        columns.forEach( column => {

          if ( column.classList.contains('oc-hidden') ) {
            column.classList.remove('oc-hidden');
          }

        });

        columns.forEach( column => {

          const term = column.dataset.term;

          if ( term != value ) {
            column.classList.add('oc-hidden');
          }

        });

      }

    }

  });

});
